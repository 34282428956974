import { useAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
import { ReactEditor, useSlate } from "slate-react";
import Modal from "~components/common/CustomModal/CustomModal";
import { useSDK } from "~hooks";
import {
  ImageElement,
  insertImage,
  isDynamicImageProperties,
  uploadImage,
} from "../Image";
import InsertImageForm, { InsertImageFormProps } from "./InsertImageForm";
import { FormValues } from "./schema";
import { Transforms, Range } from "slate";
interface InsertImageModalProps extends InsertImageFormProps {
  isOpen: boolean;
}

export const imageModalAtom = atomWithReset<{
  isOpen: boolean;
  image: ImageElement;
  isBanner: boolean;
}>({
  isOpen: false,
  image: null,
  isBanner: false,
});

function InsertImageModal(props: InsertImageModalProps) {
  const { isOpen, close, onSubmit, afterSubmit, image } = props;

  return (
    <Modal isOpen={isOpen} close={close}>
      {isOpen && (
        <InsertImageForm
          close={close}
          onSubmit={onSubmit}
          image={image}
          afterSubmit={afterSubmit}
        />
      )}
    </Modal>
  );
}
const editImage = (editor: ReactEditor, newImageNode: ImageElement) => {
  const blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    console.error("Cannot edit link without selection.");
    return;
  }

  Transforms.setNodes(editor, newImageNode, {
    at: blurSelection,
    match: (n) => n.type === "image",
    mode: "lowest",
  });

  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

export default function SlateInsertImageModal() {
  const [imageModalState] = useAtom(imageModalAtom);
  const resetModalState = useResetAtom(imageModalAtom);

  const editor = useSlate();

  const close = () => {
    resetModalState();
  };

  const afterSubmit = close;

  const sdk = useSDK();

  const onSubmit = async (formValues: FormValues) => {
    const { image, dynamicImage } = formValues;

    let url: string | undefined = undefined;

    if (typeof image === "string") {
      url = image;
    } else if (image) {
      url = await uploadImage(image, sdk);
    }

    const editedImage = imageModalState.image;

    const imageProperties = {
      url,
      href: formValues.imageLink ?? null,
    };

    if (editedImage) {
      // Dynamic image properties get merged here
      editImage(editor, { ...editedImage, ...imageProperties });
      return;
    }

    const newImage: ImageElement = {
      type: "image",
      size: imageModalState.isBanner ? "full" : "large",
      children: [],
      dynamic: dynamicImage,
      ...imageProperties,
    };

    insertImage(editor, newImage, false);
  };

  return (
    <InsertImageModal
      image={imageModalState.image}
      isOpen={imageModalState.isOpen}
      close={close}
      afterSubmit={afterSubmit}
      onSubmit={onSubmit}
    />
  );
}
