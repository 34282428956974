import * as yup from "yup";
import { replaceValuesWithPaths } from "~features/generalAdmissionEvent/form/schema.helpers";
import { ObjectSchemaDefinition } from "~features/generalAdmissionEvent/form/schema.types";
import { DynamicImageProperties, ImageElement } from "../Image";

export type FormValues = {
  image?: File | string;
  dynamicImage?: DynamicImageProperties;
  imageLink?: string;
};

export const buildInitialState = (image: ImageElement): FormValues => {
  return {
    image: image ? image.url : null,
    dynamicImage: image?.dynamic ? { ...image.dynamic } : undefined,
    imageLink: image ? image.href : null,
  };
};

export const buildSchema = (image: ImageElement) => {
  const initialState = buildInitialState(image);
  const schema = yup.object<ObjectSchemaDefinition<FormValues>>().shape({
    image: yup
      .mixed()
      .default(initialState.image)
      .when("dynamicImage", {
        is: (value: any) => !!value,
        then: yup.mixed().nullable(),
        otherwise: yup.mixed().required("Please insert an image"),
      }),
    dynamicImage: yup.object().default(initialState.dynamicImage).nullable(),
    imageLink: yup.string().default(initialState.imageLink).nullable(),
  });

  const defaultValues = schema.getDefault();
  const formNames = replaceValuesWithPaths(defaultValues);

  return { schema, formNames, defaultValues };
};
