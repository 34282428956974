import { Dispatch, SetStateAction } from "react";
import { Flex, system, SystemProps, Text } from "flicket-ui";
import { ReactEditor } from "slate-react";
import styled, { useTheme } from "styled-components";
import { Icon } from "~components";
import { Button } from "./components";
import { PencilSimple, Trash } from "@phosphor-icons/react";

export const StyledPopover = styled(Flex)<SystemProps>`
  position: absolute;
  z-index: 5000;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  padding: 8px 12px;
  align-items: center;
  font-size: 14px;

  ${system}
`;

export const Popover = ({
  url,
  editor,
  setIsOpen,
  color,
  displayRemoveButton = true,
}: {
  url?: string;
  editor: ReactEditor;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  color?: string;
  displayRemoveButton?: boolean;
}) => {
  const theme = useTheme();
  if (!color) {
    color = "#3869d4";
  }
  return (
    <>
      {url && (
        <Text
          as="a"
          variant="regular"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          contentEditable={false}
          css={`
            color: ${color};
            text-decoration: underline;
            margin-right: ${theme.space[1]}px;
            max-width: 200px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
        >
          {url}
        </Text>
      )}

      <Button
        w="30px"
        h="30px"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Icon icon={<PencilSimple size={18} />} />
      </Button>

      {displayRemoveButton && (
        <Button
          w="30px"
          h="30px"
          onClick={() => {
            editor.deleteBackward("character");
          }}
        >
          <Icon icon={<Trash size={18} />} />
        </Button>
      )}
    </>
  );
};
