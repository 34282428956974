import { Box, Flex, Spinner, Stack, Text } from "flicket-ui";
import { useAtom } from "jotai";
import { CSSProperties, useEffect, useState } from "react";
import { Editor, Element, Range, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import {
  ReactEditor,
  RenderElementProps,
  useEditor,
  useFocused,
  useSelected,
} from "slate-react";
import { useTheme } from "styled-components";
import { ExtendedFile } from "~graphql/sdk";
import { useSDK } from "~hooks";
import { getError, getGCSFile, getImage, showToast } from "~lib";
import handlePromise, { delayedPromise } from "~lib/helpers/handlePromise";
import { Icon } from "../Icon";
import { imageModalAtom } from "./InsertImageModal";
import { Button } from "./components";
import isEqual from "lodash/isEqual";
import { topOfDocumentSelection, Unremovable } from "./util";
import { PencilSimple, Trash } from "@phosphor-icons/react";
import { useQuery } from "~hooks/useQuery";
import { DynamicImageDocument } from "~graphql/typed-document-nodes";

export interface DynamicImageProperties {
  type: "eventBanner" | "membershipBanner";
  eventId?: string;
  membershipId?: string;
}

export interface ImageElement extends Element, Unremovable {
  type: "image";
  size: "full" | "large" | "medium" | "small";
  url?: string;
  dynamic?: DynamicImageProperties;
  href?: string;
}

export function isImageElement(obj: unknown): obj is ImageElement {
  const image = obj as ImageElement;
  return image.type === "image";
}

export function isDynamicImageProperties(
  obj: unknown
): obj is DynamicImageProperties {
  const properties = obj as DynamicImageProperties;
  if (!properties) return false;

  return (
    properties.type === "eventBanner" || properties.type === "membershipBanner"
  );
}

export const insertImage = (
  editor: ReactEditor,
  image: ImageElement,
  isBanner: boolean
) => {
  let blurSelection = (editor.blurSelection as any) as Range;

  if (!blurSelection || isBanner) {
    blurSelection = topOfDocumentSelection;
  }

  Transforms.insertNodes(editor, image, {
    at: blurSelection,
  });

  // add 1 to focus on the image
  const newRange: Range = {
    anchor: {
      offset: 0,
      path: [blurSelection?.anchor.path[0] + 1, 0],
    },
    focus: {
      offset: 0,
      path: [blurSelection?.focus.path[0] + 1, 0],
    },
  };

  // If this is at the top of the document
  if (isEqual(blurSelection, topOfDocumentSelection)) {
    const node = editor.children[blurSelection.anchor.path[0]];
    if (node.type === "paragraph" && node.children[0].text === "") {
      Transforms.removeNodes(editor, {
        at: blurSelection,
      });
      Transforms.insertNodes(
        editor,
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
        {
          at: blurSelection,
        }
      );
    }
    editor.selection = blurSelection;
  } else {
    editor.selection = newRange;
  }
  // add a paragraph so the user can edit access the next line in the editor
  Transforms.insertNodes(editor, [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);
  ReactEditor.focus(editor);
};

interface EditImageButtonProps {
  image: ImageElement | null;
  isBanner: boolean;
}

export const EditImageButton = (props: EditImageButtonProps) => {
  const { image, isBanner } = props;
  const [, setImageModalState] = useAtom(imageModalAtom);

  if (!image) {
    return null;
  }

  return (
    <>
      <Button
        onClick={() =>
          setImageModalState({
            isOpen: true,
            image,
            isBanner,
          })
        }
      >
        <Icon icon={<PencilSimple />} mr={"1/2"} />
        <Text variant="regular">Edit image</Text>
      </Button>
    </>
  );
};

export const DeleteImageButton = () => {
  const editor = useEditor();

  return (
    <Button
      onClick={() => {
        editor.deleteBackward("block");
      }}
    >
      <Icon icon={<Trash />} mr={"1/2"} />
      <Text variant="regular">Delete image</Text>
    </Button>
  );
};

export const uploadImage = async (
  file: File,
  sdk: ReturnType<typeof useSDK>
): Promise<string> => {
  const newFile = Object.assign(file, {
    preview: URL.createObjectURL(file),
  });

  const [error, data] = await delayedPromise(async () =>
    sdk.uploadImage({
      file: {
        file: newFile,
      },
    })
  );

  if (error) {
    showToast(getError(error, "graphQL"), "error");
    return Promise.reject(error);
  }

  return getGCSFile(data.uploadImage as ExtendedFile);
};

export const fileSelectHandler = async (
  file: File,
  editor: ReactEditor,
  sdk: ReturnType<typeof useSDK>,
  isBanner: boolean
): Promise<void | Error> => {
  const url = await uploadImage(file, sdk);

  const image: ImageElement = {
    type: "image",
    url,
    size: isBanner ? "full" : "large",
    children: [],
  };

  insertImage(editor, image, isBanner);
};

export const withImages = (editor: Editor & ReactEditor & HistoryEditor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  return editor;
};

export function EmailImageLoading() {
  return (
    <Stack
      width="100%"
      height="200px"
      background="rgba(0,0,0,0.1)"
      alignItems="center"
      justifyContent={"center"}
      direction={"vertical"}
      mb={2}
    >
      <Spinner size={30} />
    </Stack>
  );
}

export const ImageComponent = ({
  attributes,
  children,
  element,
  style,
}: RenderElementProps & { style?: CSSProperties }) => {
  const selected = useSelected();
  const focused = useFocused();
  const theme = useTheme();
  const editor = useEditor();

  const imageElement = element as ImageElement;

  const loadDynamicImage =
    !imageElement.url &&
    (imageElement?.dynamic?.eventId || imageElement?.dynamic?.membershipId);

  const { data, isLoading: dynamicImageLoading } = useQuery(
    loadDynamicImage && DynamicImageDocument,
    {
      input: {
        eventId: imageElement?.dynamic?.eventId,
        membershipId: imageElement?.dynamic?.membershipId,
      },
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  let imageUrl = (element as ImageElement).url ?? "/static/no-image.jpg";

  if (loadDynamicImage && data?.dynamicImage) {
    imageUrl = getImage(data?.dynamicImage as ExtendedFile);
  }

  // This will be the path to the image element.
  const path = ReactEditor.findPath(editor, element);

  const isBannerImage = element.size === "full" && path[0] === 0;

  let imageStyle: CSSProperties = {
    cursor: "pointer",
  };

  if (element.size) {
    imageStyle = {
      ...imageStyle,
      width:
        element.size === "small"
          ? "33%"
          : element.size === "medium"
          ? "50%"
          : element.size === "large"
          ? "100%"
          : "calc(100% + 48px)",
      ...(element.size === "full" && {
        marginLeft: "-24px",
      }),
      ...(isBannerImage && {
        marginTop: "-32px",
      }),
      ...style,
    };
  }

  return (
    <div {...attributes} style={imageStyle}>
      {/* children must be passed even not used https://github.com/ianstormtaylor/slate/issues/3930 */}
      {children}
      {dynamicImageLoading ? (
        <EmailImageLoading />
      ) : (
        <div contentEditable={false}>
          <img
            style={{
              minWidth: "100%",
              borderRadius: element.size === "full" ? 0 : "4px",
              boxShadow:
                selected && focused ? `0 0 0 2px ${theme.colors.P300}` : "none",
              marginBottom: isBannerImage ? "45px" : "0",
            }}
            src={imageUrl}
          />
        </div>
      )}
    </div>
  );
};
