import { InputWrapper, SystemProps, Text } from "flicket-ui";
import { isHotkey } from "is-hotkey";
import {
  CSSProperties,
  FC,
  HTMLProps,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import { createEditor, Editor, Node, Path, Range, Transforms } from "slate";
import { HistoryEditor, withHistory } from "slate-history";
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from "slate-react";
import { DefaultTheme, useTheme } from "styled-components";
import { toggleMark } from "./Buttons";
import { EditorWrapper } from "./components";
import { withDivider } from "./Divider";
import { GroupInsertOptions } from "./Dropdown";
import { withEmailLinks } from "./emailLinks";
import { ImageComponent, withImages } from "./Image";
import { RichTextInsertButton, withButton } from "./InsertButton";
import InsertImageModal from "./InsertImageModal";
import InsertVideoModal from "./InsertVideoModal";
import { LinkItem, withLinks } from "./Link";
import { omit, pick } from "@styled-system/props";
import {
  RichTextStyles,
  StylesChangeFn,
} from "./richTextStyles/richTextStyleTypes";
import { useOrganization } from "~hooks";
import { OrganizationFeatures } from "~lib/features";
import { VideoComponent, withVideo, VideoElement } from "./video";
import { Provider } from "jotai";
import Toolbar from "~components/common/RichText/Toolbar";
import { SuggestedLinkType } from "./InsertModal.types";
import { withPreventDeletion } from "./withPreventBlockDeletion";
import { CustomFieldElement, withFields } from "./CustomField";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
};

type FieldComponent = Omit<
  HTMLProps<HTMLInputElement>,
  "color" | "as" | "ref" | "width" | "onChange"
>;

export type ToolbarOption =
  | "divider"
  | "text-left"
  | "text-center"
  | "text-right"
  | "bold"
  | "italic"
  | "heading-one"
  | "heading-two"
  | "heading-three"
  | "heading"
  | "numbered-list"
  | "bulleted-list"
  | "link"
  | "styles"
  | "insert"
  | "align-left"
  | "align-center"
  | "align-right"
  | "media-small"
  | "media-medium"
  | "media-large"
  | "media-full"
  | "image-edit"
  | "image-delete"
  | "video-delete";

type InputProps = FieldComponent & {
  value?: string;
  label?: ReactNode;
  error?: string;
  onChange?: (val: string) => void;
  isEmail?: boolean;
  hideToolBar?: boolean;
  insertDropdownOptions?: GroupInsertOptions[];
  selectEvents?: boolean;
  footer?: ReactNode;
  toolbarProps?: SystemProps;
  toolbarOptions?: ToolbarOption[];
  toolbarImageOptions?: ToolbarOption[];
  toolbarVideoOptions?: ToolbarOption[];
  toolbarBannerOptions?: ToolbarOption[];
  editorStyle?: CSSProperties;
  richTextStyle?: RichTextStyles;
  onRichTextStyleChange?: StylesChangeFn;
  wrapEditor?: (
    editor: Editor & ReactEditor & HistoryEditor
  ) => Editor & ReactEditor & HistoryEditor;
};

export const defaultToolbarOptions: ToolbarOption[] = [
  "bold",
  "italic",
  "divider",
  "heading-one",
  "heading-two",
  "numbered-list",
  "bulleted-list",
  "divider",
  "text-left",
  "text-center",
  "text-right",
  "divider",
  "link",
];

export const defaultEmailToolbarOptions: ToolbarOption[] = [
  "bold",
  "italic",
  "divider",
  "heading-one",
  "heading-two",
  "numbered-list",
  "bulleted-list",
  "divider",
  "text-left",
  "text-center",
  "text-right",
  "divider",
  "styles",
  "divider",
  "insert",
];

export const defaultToolbarImageOptions: ToolbarOption[] = [
  "media-small",
  "media-medium",
  "media-large",
  "media-full",
  "divider",
  "align-left",
  "align-center",
  "align-right",
  "divider",
  "image-edit",
  "divider",
  "image-delete",
];

export const defaultToolbarBannerOptions: ToolbarOption[] = [
  "image-edit",
  "divider",
  "image-delete",
];

export const defaultToolbarVideoOptions: ToolbarOption[] = ["video-delete"];

const editorStyle = (
  isEmail: boolean,
  isDisabled?: boolean,
  backgroundColour?: string
): CSSProperties => {
  const disabledStyle: CSSProperties = isDisabled
    ? {
        backgroundColor: "N100",
      }
    : {};

  if (isEmail) {
    return {
      maxWidth: "570px",
      width: "100%",
      alignSelf: "center",
      backgroundColor: backgroundColour ?? "#fff",
      boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.05)",
      padding: "32px 24px",
      margin: "12px 0",
      fontFamily: "Arial, sans-serif",
    };
  } else {
    return {
      overflowY: "auto",
      backgroundColor: "#fff",
      padding: "12px 16px",
      fontSize: "16px",
      ...disabledStyle,
    };
  }
};

export const defaultRichTextValue = JSON.stringify([
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
]);

export const RichText: FC<InputProps & SystemProps> = ({
  name,
  label,
  error,
  onChange,
  value: rawValue,
  footer = null,
  // set this to true for email rich text editor
  isEmail = false,
  hideToolBar = false,
  disabled,
  insertDropdownOptions,
  selectEvents = false,
  toolbarProps,
  toolbarOptions = defaultToolbarOptions,
  toolbarImageOptions = defaultToolbarImageOptions,
  toolbarVideoOptions = defaultToolbarVideoOptions,
  toolbarBannerOptions = defaultToolbarBannerOptions,
  editorStyle: userEditorStyle = {},
  richTextStyle,
  wrapEditor,
  onRichTextStyleChange,
  ...props
}) => {
  const value = rawValue === null ? undefined : rawValue;
  const { hasFeature } = useOrganization();

  const filteredToolbarImageOptions = toolbarImageOptions.filter((option) => {
    if (
      option === "media-full" &&
      hasFeature(OrganizationFeatures.DisableMJMLEmail)
    ) {
      return false;
    }
    return true;
  });

  const formatValue = (value: string): Node[] => {
    try {
      const parsedValue = JSON.parse(value) as Node[];
      return parsedValue;
    } catch (e) {
      return JSON.parse(defaultRichTextValue) as Node[];
    }
  };

  const editor = useMemo(() => {
    const baseEditor = withPreventDeletion(
      withButton(
        withFields(
          withDivider(
            withImages(
              withVideo(
                withLinks(
                  withEmailLinks(withReact(withHistory(createEditor())))
                )
              )
            )
          )
        )
      )
    );

    if (wrapEditor) {
      return wrapEditor(baseEditor);
    }

    return baseEditor;
  }, [wrapEditor]);

  const renderElement = useCallback(
    (props: RenderElementProps) => (
      <Element {...props} isEmail={isEmail} richTextStyle={richTextStyle} />
    ),
    [isEmail, richTextStyle]
  );

  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );

  const refocusEditor = (editor: ReactEditor, path?: Path) => {
    const { selection } = editor;
    if (path) {
      ReactEditor.focus(editor);
      Transforms.select(editor, path);
      return;
    }
    if (!selection || !selection.anchor || !selection.focus) {
      return;
    }
    ReactEditor.focus(editor);
    Transforms.select(editor, selection.focus);
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey] as string;
        toggleMark(editor, mark);
      }
    }
    // https://github.com/ianstormtaylor/slate/issues/5245#issuecomment-1383752042
    if (event.key === "Enter") {
      const { selection } = editor;
      if (selection && Range.isCollapsed(selection)) {
        const node = editor.children[selection.anchor.path[0]];
        if (node && ["image", "button"].includes(node.type as string)) {
          event.preventDefault();
          const nextIndex = selection.anchor.path[0] + 1;
          const next = editor.children[nextIndex];
          if (next) {
            refocusEditor(editor, [nextIndex]);
            return;
          }
          const emptyElement = {
            type: "paragraph",
            children: [{ text: "" }],
          };
          Transforms.insertNodes(editor, emptyElement, {
            at: [editor.children.length],
          });
          const last = Editor.last(editor, []);
          refocusEditor(editor, last[1]);
          return;
        }
      }
    }
  };

  return (
    <Provider>
      <InputWrapper label={label} name={name} error={error} position="relative">
        <EditorWrapper
          isValid={!error}
          isEmail={isEmail}
          className="editor-wrapper"
          backgroundColor={richTextStyle?.backgroundColour}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          {...pick(props)}
        >
          <Slate
            editor={editor}
            value={formatValue(value)}
            onChange={(value) => onChange?.(JSON.stringify(value))}
          >
            {!disabled && !hideToolBar && (
              <Toolbar
                toolbarOptions={toolbarOptions}
                toolbarImageOptions={filteredToolbarImageOptions}
                toolbarVideoOptions={toolbarVideoOptions}
                toolbarBannerOptions={toolbarBannerOptions}
                insertDropdownOptions={insertDropdownOptions}
                selectEvents={selectEvents}
                toolbarProps={toolbarProps}
                richTextStyle={richTextStyle}
                onRichTextStyleChange={onRichTextStyleChange}
              />
            )}
            <Editable
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              {...omit(props)}
              name={name}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              readOnly={disabled}
              spellCheck
              style={{
                ...editorStyle(isEmail, disabled, richTextStyle?.bodyColour),
                ...userEditorStyle,
                // Make sure the corner radius are the same as the
                // editor outline, or else they look chopped up.
                borderRadius: 8,
              }}
              onKeyDown={(event) => {
                handleOnKeyDown(event);
              }}
              onBlur={() => {
                // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
                editor.blurSelection = editor.selection;
              }}
            />
            {footer}
            <InsertImageModal />
            <InsertVideoModal />
          </Slate>
        </EditorWrapper>
      </InputWrapper>
    </Provider>
  );
};

type RenderFunctionProps = RenderElementProps & {
  isEmail: boolean;
  style: CSSProperties;
  headingStyle: CSSProperties;
  richTextStyle: RichTextStyles;
  theme: DefaultTheme;
};

const Element = ({
  attributes,
  children,
  element,
  isEmail,
  richTextStyle,
}: RenderElementProps & {
  isEmail: boolean;
  richTextStyle: RichTextStyles;
}) => {
  const theme = useTheme();

  const headingStyle = {
    color: richTextStyle?.headingColour,
  };

  let style: CSSProperties = {
    // So text does not overflow the editor container
    wordBreak: "break-word",
  };

  if (element.align) {
    if (element.type === "image" && element.size !== "full") {
      style = {
        ...style,
        margin: `auto ${element.align === "right" ? "0" : "auto"} auto ${
          element.align === "left" ? "0" : "auto"
        }`,
      };
    } else {
      style = {
        textAlign: element.align as "left" | "center" | "right",
        ...style,
      };
    }
  }

  const rendererProps: RenderFunctionProps = {
    element,
    style,
    headingStyle,
    theme,
    attributes,
    children,
    isEmail,
    richTextStyle,
  };

  return renderer(rendererProps);
};

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  return <span {...attributes}>{children}</span>;
};

function renderer({
  element,
  style,
  headingStyle,
  theme,
  attributes,
  children,
  isEmail,
  richTextStyle,
}: RenderFunctionProps) {
  switch (element.type) {
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <Text
          as="h1"
          variant="header.L"
          style={{ ...style, ...headingStyle }}
          {...attributes}
        >
          {children}
        </Text>
      );
    case "heading-two":
      return (
        <Text
          as="h2"
          variant="header.M"
          style={{ ...style, ...headingStyle }}
          {...attributes}
        >
          {children}
        </Text>
      );
    case "heading-three":
      return (
        <Text
          as="h3"
          variant="header.S"
          style={{ ...style, ...headingStyle }}
          {...attributes}
        >
          {children}
        </Text>
      );
    case "list-item":
      return (
        <li style={{ ...style }} {...attributes}>
          <Text variant="regular">{children}</Text>
        </li>
      );
    case "numbered-list":
      return (
        <ol style={{ ...style }} {...attributes}>
          <Text variant="regular">{children}</Text>
        </ol>
      );
    case "link":
      return (
        <LinkItem
          attributes={attributes}
          url={element.url as string}
          style={{
            ...style,
            color: richTextStyle?.linkColour ?? theme.colors.N800,
          }}
          content={element.content as string}
          isEmail={isEmail}
          suggestedLink={element.suggestedLink as SuggestedLinkType}
          eventId={element.eventId as string}
          releaseId={element.releaseId as string}
          membershipId={element.membershipId as string}
        >
          {children}
        </LinkItem>
      );
    case "image":
      return (
        <ImageComponent attributes={attributes} element={element} style={style}>
          {children}
        </ImageComponent>
      );

    case "video":
      return (
        <VideoComponent
          attributes={attributes}
          element={element as VideoElement}
          style={style}
        >
          {children}
        </VideoComponent>
      );

    case "divider":
      return (
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: theme.colors.N200,
            margin: `${theme.space[4]}px 0`,
          }}
          {...attributes}
        >
          {children}
        </div>
      );
    case "summary":
      return (
        <div
          style={{
            width: "100%",
            backgroundColor: theme.colors.N200,
            border: `1px solid ${theme.colors.N600}`,
            textAlign: "center",
            padding: `${theme.space[4]}px 0`,
            margin: `${theme.space[4]}px 0`,
          }}
          contentEditable={false}
          {...attributes}
        >
          {element.content}
        </div>
      );
    case "button":
      return (
        <RichTextInsertButton
          attributes={attributes}
          url={element.url as string}
          content={element.content as string}
          color={richTextStyle?.buttonColour}
          suggestedLink={element.suggestedLink as SuggestedLinkType}
          eventId={element.eventId as string}
          releaseId={element.releaseId as string}
        >
          {children}
        </RichTextInsertButton>
      );
    case "field":
      return (
        <CustomFieldElement content={element.content as string}>
          {children}
        </CustomFieldElement>
      );
    default:
      return (
        <Text
          as="p"
          variant="regular"
          mb={isEmail ? undefined : 1}
          style={{
            ...style,
            color: richTextStyle?.paragraphColour,
          }}
          {...attributes}
        >
          {children}
        </Text>
      );
  }
}
