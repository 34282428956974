import { ReactEditor } from "slate-react";
import { Range } from "slate";
import { ImageElement } from "./Image";
import isEqual from "lodash/isEqual";
import { VideoElement } from "./video";

export const topOfDocumentPoint = {
  offset: 0,
  path: [0, 0],
};

export const topOfDocumentSelection = {
  anchor: topOfDocumentPoint,
  focus: topOfDocumentPoint,
};

export interface Unremovable {
  isRemovable?: false;
}

export function isImageElement(node: unknown): node is ImageElement {
  return (node as ImageElement)?.type === "image";
}

export const selectedImage = (editor: ReactEditor) => {
  const { selection } = editor;
  const node = editor.children?.[selection?.anchor?.path?.[0]];

  const isBannerImage =
    isImageElement(node) &&
    node.size === "full" &&
    isEqual(selection, topOfDocumentSelection);

  const isImageSelected =
    selection &&
    Range.isCollapsed(selection) &&
    isImageElement(node) &&
    !isBannerImage;

  return isImageSelected ? node : null;
};

export const selectedBannerImage = (editor: ReactEditor) => {
  const { selection } = editor;
  const node = editor.children?.[selection?.anchor?.path?.[0]];

  const isBannerImage =
    isImageElement(node) &&
    node.size === "full" &&
    isEqual(selection, topOfDocumentSelection);

  return isBannerImage ? node : null;
};

export function isVideoElement(node: unknown): node is VideoElement {
  return (node as VideoElement)?.type === "video";
}

export const selectedVideo = (editor: ReactEditor) => {
  const { selection } = editor;
  const node = editor.children?.[selection?.anchor?.path?.[0]];

  const isVideoSelected =
    selection && Range.isCollapsed(selection) && isVideoElement(node);

  return isVideoSelected ? node : null;
};
