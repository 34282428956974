import { Box } from "flicket-ui";
import { FC, useEffect, useRef, useState } from "react";
import { Editor, Element, Range, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import {
  ReactEditor,
  RenderElementProps,
  useFocused,
  useSelected,
  useSlate,
} from "slate-react";
import { Modal } from "~components";
import { useOnClickOutside } from "~hooks";
import { addProtocolToURL } from "~lib/helpers/addProtocolToURL";
import { textColourFromLuminance } from "~lib/helpers/colours";
import { InsertModalContent } from "./InsertModal";
import { SuggestedLinkType } from "./InsertModal.types";
import { Popover, StyledPopover } from "./Popover";
import { Unremovable } from "./util";

export interface ButtonElement extends Element, Unremovable {
  type: "button";
  size: "full" | "large" | "medium" | "small";
  url?: string;
  urlDisplayText?: string;
  suggestedLink?: SuggestedLinkType;
  eventId?: string;
  releaseId?: string;
  membershipId?: string;
}

export const withButton = (editor: Editor & ReactEditor & HistoryEditor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "button" ? true : isVoid(element);
  };

  return editor;
};

export const insertButton = (
  editor: ReactEditor,
  content: string,
  url?: string,
  suggestedLink?: SuggestedLinkType,
  eventId?: string,
  releaseId?: string,
  membershipId?: string
) => {
  let blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }

  url = addProtocolToURL(url);

  const button = {
    type: "button",
    url,
    content,
    suggestedLink,
    eventId,
    releaseId,
    membershipId,
    children: [{ text: "" }],
  };

  Transforms.insertNodes(editor, button, {
    at: blurSelection,
  });
  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

const editButton = (
  editor: ReactEditor,
  content: string,
  url?: string,
  suggestedLink?: SuggestedLinkType,
  eventId?: string,
  releaseId?: string,
  membershipId?: string
) => {
  const blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    console.error("Cannot edit button without selection.");
    return;
  }

  url = addProtocolToURL(url);

  Transforms.setNodes(
    editor,
    {
      url,
      content,
      suggestedLink,
      eventId,
      releaseId,
      membershipId,
    },
    {
      at: blurSelection,
    }
  );

  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

export const RichTextInsertButton: FC<
  Pick<RenderElementProps, "attributes"> & {
    url?: string;
    content: string;
    color: string;
    suggestedLink?: SuggestedLinkType;
    eventId?: string;
    releaseId?: string;
    membershipId?: string;
  }
> = ({
  attributes,
  url,
  children,
  content,
  color,
  suggestedLink,
  eventId,
  releaseId,
  membershipId,
}) => {
  if (suggestedLink) {
    url = undefined;
  }

  const selected = useSelected();
  const focused = useFocused();

  const editor = useSlate();
  const [isOpen, setIsOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsPopoverOpen(false));

  useEffect(() => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const buttonNodeArr = Array.from(
        Editor.nodes(editor, {
          match: (n) => n.type === "button",
        })
      );
      if (buttonNodeArr.length && selected && focused) {
        setIsPopoverOpen(true);
      }
    } else {
      setIsPopoverOpen(false);
    }
  }, [editor.selection]);

  const [selectedButton] = Editor.nodes<ButtonElement>(editor, {
    at: editor.selection,
    match: (n) => n.type === "button",
  });

  //  Border based button https://litmus.com/blog/a-guide-to-bulletproof-buttons-in-email-design
  return (
    // added contentEditable={false} and style={{ userSelect: "none" }} to prevent the "Cannot resolve a Slate point from DOM point" error
    <div contentEditable={false} style={{ userSelect: "none" }}>
      <table width="100%" cellSpacing="0" cellPadding="0">
        <tr>
          <td align="center">
            <table cellSpacing="0" cellPadding="0">
              <tr>
                <td>
                  <Box
                    position={"relative"}
                    display="flex"
                    justifyContent={"center"}
                  >
                    <a
                      {...attributes}
                      css={`
                        color: ${textColourFromLuminance(color)} !important;
                        font-weight: bold;
                        border-radius: 5px;
                        background-color: ${color};
                        border-top: 12px solid ${color};
                        border-bottom: 12px solid ${color};
                        border-right: 18px solid ${color};
                        border-left: 18px solid ${color};
                        display: inline-block;
                        text-decoration: none;
                      `}
                    >
                      {children}
                      {content}
                    </a>
                    {isPopoverOpen && (
                      <StyledPopover ref={ref} bottom={"calc(100% + 8px)"}>
                        <Popover
                          editor={editor}
                          url={url}
                          setIsOpen={setIsOpen}
                          displayRemoveButton={
                            selectedButton
                              ? selectedButton[0]?.isRemovable !== false
                              : true
                          }
                        />
                      </StyledPopover>
                    )}
                  </Box>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <Modal isOpen={isOpen} close={() => setIsOpen(false)}>
        <InsertModalContent
          setIsOpen={setIsOpen}
          isEmail
          isEdit
          defaultValues={{
            event: { id: eventId, name: "" },
            url,
            urlDisplayText: content,
            suggestedLink,
            release: { id: releaseId, name: "" },
            membership: { id: membershipId, name: "" },
            type: "button",
          }}
          onSuccess={(options) => {
            const {
              urlDisplayText,
              url,
              suggestedLink,
              event,
              release,
              membership,
            } = options;
            editButton(
              editor,
              urlDisplayText,
              url,
              suggestedLink,
              event?.id,
              release?.id,
              membership?.id
            );
          }}
        />
      </Modal>
    </div>
  );
};
