import { ReactNode } from "react";
import { Editor, Element, Range, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import { ReactEditor } from "slate-react";
import styled from "styled-components";
import { CustomFields } from "~features/broadcast/customFields.enum";
import { TextT } from "@phosphor-icons/react";
import { RichTextDropdownOption } from "./Dropdown";
import { Unremovable } from "./util";

export interface FieldElement extends Element, Unremovable {
  type: "field";
  content: string;
}

export const CustomFieldsOptions: RichTextDropdownOption[] = [
  { label: "First name", value: CustomFields.FIRST_NAME, icon: <TextT /> },
  { label: "Last name", value: CustomFields.LAST_NAME, icon: <TextT /> },
  { label: "Event name", value: CustomFields.EVENT_NAME, icon: <TextT /> },
];

export const withFields = (editor: Editor & ReactEditor & HistoryEditor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "field" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "field" ? true : isVoid(element);
  };

  return editor;
};

export const insertField = (editor: ReactEditor, content: string) => {
  let blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }
  const field: FieldElement = {
    type: "field",
    content,
    children: [{ text: "" }],
  };
  Transforms.insertNodes(editor, field, {
    at: blurSelection,
  });

  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

export const StyledCustomFieldElement = styled.span`
  box-sizing: border-box;
  background: ${(p) => p.theme.colors.N100};
  mix-blend-mode: multiply;
  border: 1px solid ${(p) => p.theme.colors.N300};
  border-radius: 4px;
  padding: 0 4px;
  display: inline-block;
  line-height: 1;
`;

export const CustomFieldElement = ({
  content,
  children,
}: {
  content: string;
  children: ReactNode;
}) => (
  <StyledCustomFieldElement contentEditable={false}>
    {children}
    {CustomFieldsOptions.find((option) => option.value === content)?.label}
  </StyledCustomFieldElement>
);
