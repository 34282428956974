import { Editor, Element } from "slate";
import { HistoryEditor } from "slate-history";
import { ReactEditor } from "slate-react";

const isUnremovableElementBefore = (editor: ReactEditor) => {
  const { selection } = editor;
  const before = Editor.before(editor, selection);
  if (!before) return false;
  const [beforeNode] = Editor.nodes(editor, {
    at: before,
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.isRemovable === false,
  });
  return !!beforeNode;
};

const isUnremovableElementAfter = (editor: ReactEditor) => {
  const { selection } = editor;
  const after = Editor.after(editor, selection);
  if (!after) return false;
  const [beforeNode] = Editor.nodes(editor, {
    at: after,
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.isRemovable === false,
  });
  return !!beforeNode;
};

const isUnremovableVoidElementSelected = (editor: ReactEditor) => {
  const { selection } = editor;
  const [selectedNode] = Editor.nodes(editor, {
    at: selection,
    match: (n) => Editor.isVoid(editor, n) && n.isRemovable === false,
  });
  return !!selectedNode;
};

export const withPreventDeletion = (
  editor: Editor & ReactEditor & HistoryEditor
) => {
  const { deleteBackward, deleteForward } = editor;

  editor.deleteBackward = (unit) => {
    if (
      isUnremovableElementBefore(editor) ||
      isUnremovableVoidElementSelected(editor)
    ) {
      return;
    }
    deleteBackward(unit);
  };

  editor.deleteForward = (unit) => {
    if (
      isUnremovableElementAfter(editor) ||
      isUnremovableVoidElementSelected(editor)
    ) {
      return;
    }

    deleteForward(unit);
  };

  return editor;
};
